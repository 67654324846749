<div class="summary-medical-order-container directory-background">
    <h1 *ngIf="!patient?.nombre" [ngClass]="{'skeleton-item background-grey': initialLoading}">
        {{ 'shared.medicalOrder.summary.text1' | translate:locale.language:{ tipoSolicitud: (title | translate:locale.language) } }}
    </h1>
    <h1 *ngIf="patient?.nombre" [ngClass]="{'skeleton-item background-grey': initialLoading}">
        {{ 'shared.medicalOrder.summary.text2' | translate:locale.language:{patientName: patient?.nombre, tipoSolicitud: (title | translate:locale.language)} }}
    </h1>
    <div class="h1-mobile" [ngClass]="{'skeleton-item background-grey': initialLoading}"></div>
    <div class="card" [ngClass]="{'android': isAndroid, 'show-footer': inWorflow}">
        <div class="order-header">
            <img class="clinic-logo" [src]="history?.ubicacionFotoLogoTitulo" [ngClass]="{'skeleton-item background-grey': initialLoading}">
            <div class="clinic-logo" [ngClass]="{'skeleton-item background-grey': initialLoading}"></div>
            <div class="clinic-name-logo" [ngClass]="{'skeleton-item background-grey': initialLoading}"></div>
            <div class="title">
                <span class="bold" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                    {{ requestTypeName | translate:locale.language:{requestNumber: requestNumber} }}
                </span>
                <span class="bold clinic-info-small" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                    Fecha: {{dateString}}
                </span>
            </div>
        </div>
        <div class="medial-order-content" >
            <div class="location">
                <div class="title">
                    <div class="title-section" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        <img src="https://s3.amazonaws.com/docs.public/images/iconos/pdf/Doc.svg" />
                        <span> {{ 'shared.medicalOrder.summary.patientTitle' | translate:locale.language }} </span>
                    </div>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">{{patient?.nombre}} {{patient?.apellido}}</span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">{{patient?.fechaNacimiento | ageFromBirthday}}</span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">{{patient?.cedula || 'No disponible'}}</span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}" *ngIf="patient?.emailParticular">
                        {{patient?.emailParticular || 'N/A'}}
                    </span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}" *ngIf="patient?.telefonoCelularParticular">
                        {{patient?.telefonoCelularParticular}}
                    </span>
                </div>
                <div class="title">
                    <div class="title-section" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        <img src="https://s3.amazonaws.com/docs.public/images/iconos/pdf/Estetoscopio.svg" />
                        <span> {{ 'shared.medicalOrder.summary.healthcareProfesionalTitle' | translate:locale.language }} </span>
                    </div>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{publicProfile?.nombrePersona}}
                    </span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{publicProfile?.especialidad || 'N/A'}}
                    </span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{history?.empresaPortal?.denominacion || ''}}
                    </span>
                    <span class="truncate" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{history?.empresaPortal?.direccionLaboral || ''}} {{history?.empresaPortal?.nombreCanonicoDivisionTerritorialLaboral || ''}}
                    </span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{history?.empresaPortal?.numeroTelCelular || 'N/A'}}<span *ngIf="history?.empresaPortal?.numeroTelParticular">, {{history?.empresaPortal?.numeroTelParticular || ''}}</span>
                    </span>
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{history?.empresaPortal?.emailLaboral || history?.empresaPortal?.emailParticular || 'N/A'}}
                    </span>
                </div>
                <mat-divider class="mat-divider mat-divider-horizontal w-100" role="separator"></mat-divider>
            </div>
            <div *ngIf="diagnosticos.length && !initialLoading" class="diagnosticos-container">
                <span class="titulo">{{'shared.medicalOrder.summary.' + diagnosticosLabel | translate:locale.language}}:</span>
                <span class="diagnosticos">{{diagnosticos}}</span>
            </div>
            <ol [ngClass]="{'sin-numeros': showNumbers()}">
                <li *ngFor="let item of atenciones; let i = index" class="subitems">
                    <span [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        <span>{{item.nombreConcepto || item.nombreConceptoClinico}}</span>
                        <span *ngIf="item.cantidad"> (Total: #{{item.cantidad || item.nombreConceptoClinico}})</span>
                        <br *ngIf="item.valorConcepto" />
                        <span *ngIf="item.valorConcepto">{{item.valorConcepto}}</span>
                    </span>
                    <ul *ngIf="item.items" class="subitem">
                        <li *ngFor="let item2 of item.items" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                            {{item2.nombreConcepto || item2.nombreConceptoClinico}}<br *ngIf="item2.valorConcepto" />
                            <span *ngIf="item2.valorConcepto">{{item2.valorConcepto}}</span>
                        </li>
                    </ul>
                </li>
            </ol>
            <div *ngIf="comentarios && !initialLoading" class="comentarios">
                <div class="titulo">{{'shared.medicalOrder.summary.comment' | translate:locale.language}}</div>
                <div class="comentario" *ngIf="!showHtmlComment">{{comentarios.valorConcepto}}</div>
                <div class="comentario-html" *ngIf="showHtmlComment" [innerHtml]="htmlComment"></div>
            </div>
            <div *ngIf="!initialLoading" class="doctor-sign">
                <img *ngIf="publicProfile?.firmaImg && !initialLoading" [src]="publicProfile?.firmaImg | safeUrl" />
                <div *ngIf="publicProfile && !initialLoading" class="title">
                    <span class="bold">{{publicProfile.nombrePersona}}</span>
                    <span class="bold">{{publicProfile.especialidad || 'N/A'}}</span>
                </div>
            </div>
        </div>

        <app-summary-order-footer
            *ngIf="!initialLoading"
            [idPaciente]="idPaciente"
            [inWorflow]="inWorflow"
            [idTipoSolicitud]="requestType"
            [nombreTipoSolicitud]="requestTypeName"
            [file]="file"
        ></app-summary-order-footer>
    </div>
</div>